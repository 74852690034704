import { toast } from "react-toastify";
import AuctionPortal from "../../apis/AuctionPortal";
import history from "../../history";
import {
  GENERATE_OTP_SUCCESS,
  GENERATE_OTP_FAIL,
  VALIDATE_OTP_SUCCESS,
  VALIDATE_OTP_FAIL,
  CLEAR_OTP_STATE,
} from "../types";
import CryptoJS from "crypto-js"; //Added By Mayur

export const resetOtpState = () => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_OTP_STATE });
  } catch (error) {}
};

export const generateOTP = (username) => async (dispatch) => {
  try {
    document.getElementById("spinner").classList.add("spinnerDisplay");
    //Added By Mayur
    var k='';
    var d = new Date().toLocaleString();
    for (let index = 0; index <4; index++) {
      k += "8080";
    }

    var key = CryptoJS.enc.Utf8.parse(k);
    var iv = CryptoJS.enc.Utf8.parse(k);
    if(username.length<12){
    username = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(username+"~"+d),
      key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    ).toString();
    
    
    while(username.includes('/'))
      username = username.replace('/','zxcvb')
    while(username.includes('+'))
      username = username.replace('+','plus')
      //End
    }
    const res = await AuctionPortal.get(`/GetOTP/${username}`);
    dispatch({
      type: GENERATE_OTP_SUCCESS,
      payload: { ...res.data, username: username },
    });

    document.getElementById("spinner").classList.remove("spinnerDisplay");
  } catch (error) {
    document.getElementById("spinner").classList.remove("spinnerDisplay");
    toast.info(error.response.data.Description[0]);
    dispatch({
      type: GENERATE_OTP_FAIL,
    });
  }
};

export const loginOTP = (username) => async (dispatch) => {
  try {
    document.getElementById("spinner").classList.add("spinnerDisplay");
    //Added By Mayur
    var k='';
    var d = new Date().toLocaleString();
    for (let index = 0; index <4; index++) {
      k += "8080";
    }

    var key = CryptoJS.enc.Utf8.parse(k);
    var iv = CryptoJS.enc.Utf8.parse(k);
    if(username.length<12){
    username = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(username+"~"+d),
      key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    ).toString();
    
    
    while(username.includes('/'))
      username = username.replace('/','zxcvb')
    while(username.includes('+'))
      username = username.replace('+','plus')
      //End
    }
    const res = await AuctionPortal.get(`/loginOTP/${username}`);
    dispatch({
      type: GENERATE_OTP_SUCCESS,
      payload: { ...res.data, username: username },
    });

    document.getElementById("spinner").classList.remove("spinnerDisplay");
  } catch (error) {
    document.getElementById("spinner").classList.remove("spinnerDisplay");
    toast.info(error.response.data.Description[0]);
    dispatch({
      type: GENERATE_OTP_FAIL,
    });
  }
};

export const validateOtp = (username, OTP) => async (dispatch) => {
  try {
    document.getElementById("spinner").classList.add("spinnerDisplay");
    var k = '';
    var d = new Date().toLocaleString();
    for (let index = 0; index <4; index++) {
      k += "8080";
    }
    OTP = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(OTP+"~"+d),
    CryptoJS.enc.Utf8.parse(k),
    {
      keySize: 128 / 8,
      iv: CryptoJS.enc.Utf8.parse(k),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }).toString();
    var req = {
      "username" : username,
        "OTP":OTP
    }
    const res = await AuctionPortal.post(`/ValidateOtp`,req);
    if(res.status==200)
    {
      
      const responseData = res.data;
      let description = responseData.description;
      var k='';
      for (let index = 0; index < 4; index++) {
        k += "8080"; 
      }
      description= await CryptoJS.AES.decrypt(
        description,
        CryptoJS.enc.Utf8.parse(k),
        {
          keySize: 128 / 8,
          iv:CryptoJS.enc.Utf8.parse(k),
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      ).toString(CryptoJS.enc.Utf8);
      description = description.substring(0,description.indexOf('~'));

      if(description=="OTP Verified to process")
      {
          dispatch({
                type: VALIDATE_OTP_SUCCESS,
                payload: { ...res.data, otp: OTP },
    });
      }
      else
      {
        dispatch({
          type: VALIDATE_OTP_FAIL,
        });
      }
    }

    document.getElementById("spinner").classList.remove("spinnerDisplay");
  } catch (error) {
    document.getElementById("spinner").classList.remove("spinnerDisplay");
    
    if(!error.response.data)
      window.location = "/";
      if(error.response.data.Description[0]==="The OTP Limit Reach")
      window.location.href="/";
    toast.error(error.response.data.Description[0]);
    dispatch({
      type: VALIDATE_OTP_FAIL,
    });
  }
};

export const setnewpassword =
  (otp, newPassword, newPasswordConfirm, userId) => async (dispatch) => {
    try {
      document.getElementById("spinner").classList.add("spinnerDisplay");
      var newPassData = {
        otp: otp,
        newPassword: newPassword,
        newPasswordConfirm: newPasswordConfirm,
        userId: userId,
      };
      await AuctionPortal.post(`/SetPassword`, newPassData);

      document.getElementById("spinner").classList.remove("spinnerDisplay");
      history.push("/login");
      toast("Password Reset Successfull");
    } catch (error) {
      document.getElementById("spinner").classList.remove("spinnerDisplay");
      toast.error(error.response.data.Description[0]);
    }
  };
